<template>
  <div id="divMyWalletsWrapper">
    <VueContentHolder :padding="[20, 20, 40, 20]">
      <div class="page-wrapper">
        <div
          class="partner-card-list"
          v-for="p in customerDetail.partners.filter(i => i.code === 'PMI')"
          :key="p.code"
        >
          <router-link class="card-link" :to="walletDetailURL">
            <div class="card">
              <div class="card-top">
                <div class="card-name">
                  Puan <br />
                  Detayları
                </div>
                <div
                  class="partner-logo"
                  :style="{
                    backgroundImage: `url(${p.image})`,
                  }"
                ></div>
              </div>
              <div class="card-bottom">
                <div class="point">{{ pointFormatter(p.point) }} Puan</div>
                <div class="partner">{{ p.desc.toUpperCase() }}</div>
              </div>
            </div>
          </router-link>
          <BrandButton
            class="gift-card-button"
            v-if="isVisibleGiftCatalog"
            id="gift_card_button"
            @click="goToGiftCatalog()"
            :iconName="icons.tag.name"
            iconColor="#fff"
            :iconSize="{ width: icons.tag.width, height: icons.tag.height }"
            :contentAlignment="alignment.center"
          >
            <VueText color="white-100" sizeLevel="6" weightLevel="2">Hediye Al</VueText>
          </BrandButton>
          <div class="card card-blue">
            <div class="card-top">
              <div class="card-name">
                İndirim <br />
                Kartım
              </div>
              <div
                class="partner-logo"
                :style="{
                  backgroundImage: `url(${p.image})`,
                }"
              ></div>
            </div>
            <div class="card-bottom">
              <div class="point">{{ pointFormatter(p.amount) }} ₺</div>
              <div class="partner">{{ p.desc.toUpperCase() }}</div>
            </div>
          </div>
          <BrandButton
            class="gift-card-button"
            id="gift_card_button"
            :target="convertScoreURL"
            iconColor="#fff"
            :iconName="icons.refresh.name"
            :iconSize="{ width: icons.refresh.width, height: icons.refresh.height }"
            as="router-link"
            @click.native="dataLayerEvent('indirime_donustur')"
          >
            <VueText color="white-100" sizeLevel="6" weightLevel="2"
              >Puanını İndirime Dönüştür</VueText
            >
          </BrandButton>
        </div>
      </div>
    </VueContentHolder>
  </div>
</template>
<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder';
import VueText from '@/components/shared/VueText/VueText';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import {
  COMPONENT_SIZES,
  PARTNER_IMAGES,
  ICON_VARIABLES,
} from '@/constants/component.constants.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesMyWallets from '@/router/routes/secure/RoutesMyWallets';
import RoutesRoot from '@/router/routes/RoutesRoot';
import StorageProps from '@/mixins/storageProps.js';
import { Common } from '@/services/Api/index';
import { numberFormatter } from '@/utils/publicHelper.js';
import StorageHelper from '@/utils/storageHelper';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'MyWalletsHome',
  mixins: [StorageProps, gtmUtils],
  components: {
    VueContentHolder,
    VueText,
    BrandButton,
  },
  data() {
    return {
      convertScoreURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.ConvertScore.path}`,
      walletDetailURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.WalletsDetail.path}`,
      detail: null,
    };
  },
  methods: {
    dataLayerEvent(event) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent(event, {
        location: 'cüzdanlarım',
      });
    },
    pointFormatter(value) {
      return numberFormatter(value);
    },
    getPointLink(p) {
      return `${this.walletDetailURL}?partner=${p.code}`;
    },
    selectGiftCard(card) {
      this.$router.push(`${this.convertScoreURL}?code=${card.code}`);
    },
    goToGiftCatalog() {
      Common.getGiftCatalogLink().then(res => {
        const {
          Data: { url },
        } = res.data;
        window.location = url;
      });
    },
  },
  computed: {
    getMenuItems() {
      return new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
    },

    isVisibleGiftCatalog() {
      return this.getMenuItems.menuItems.filter(f => f.screenCode === 7).length > 0;
    },
    images() {
      return PARTNER_IMAGES;
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    alignment() {
      return FLEX_JUSTIFIES;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  @media screen and (min-width: 375px) {
    max-width: 335px;
    margin: 0 auto;
  }
  .card {
    background: rgb(210, 0, 81);
    background-image: url('~@/assets/bg/bg-wallet-card.png');
    background-repeat: no-repeat;
    height: 196px;
    display: flex;
    flex-direction: column;
    padding: 13px 13px 20px 26px;
    margin-bottom: 20px;
    border-radius: 10px;
    color: #fff;
    &-blue {
      margin-top: 40px;
      background-image: url('~@/assets/bg/bg-wallet-card-blue.png');
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &-bottom {
      margin-top: auto;
    }
    &-name {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: -2px;
    }
    .partner-logo {
      width: 120px;
      height: 50px;
      background-color: palette-color-level('white', '100');
      background-repeat: no-repeat;
      background-position: center center;
      min-width: palette-space-level('100');
      border-radius: palette-radius-level('3');
      background-size: 50%;
    }
    .point {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-bottom: 6px;
    }
    .partner {
      font-size: 16px;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  /deep/ .gift-card-button {
    border-radius: 10px;
    height: 60px !important;
    background-image: linear-gradient(0, #d20051 10%, #ffa300 160%);
    span:first-child {
      padding-right: 20px !important;
    }
  }
  .card-link {
    text-decoration: none;
  }
}
</style>
